@font-face {
  font-family: 'COOP';
  src: url('COOP-Medium.eot');
  src: url('COOP-Medium.eot?#iefix') format('embedded-opentype'),
    url('COOP-Medium.woff2') format('woff2'),
    url('COOP-Medium.woff') format('woff'),
    url('COOP-Medium.ttf') format('truetype'),
    url('COOP-Medium.svg#COOP-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'COOP';
  src: url('COOP-Regular.eot');
  src: url('COOP-Regular.eot?#iefix') format('embedded-opentype'),
    url('COOP-Regular.woff2') format('woff2'),
    url('COOP-Regular.woff') format('woff'),
    url('COOP-Regular.ttf') format('truetype'),
    url('COOP-Regular.svg#COOP-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'COOP';
  src: url('COOP-SemiBold.eot');
  src: url('COOP-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('COOP-SemiBold.woff2') format('woff2'),
    url('COOP-SemiBold.woff') format('woff'),
    url('COOP-SemiBold.ttf') format('truetype'),
    url('COOP-SemiBold.svg#COOP-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'COOP';
  src: url('COOP-Bold.eot');
  src: url('COOP-Bold.eot?#iefix') format('embedded-opentype'),
    url('COOP-Bold.woff2') format('woff2'), url('COOP-Bold.woff') format('woff'),
    url('COOP-Bold.ttf') format('truetype'),
    url('COOP-Bold.svg#COOP-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'COOP';
  src: url('COOP-Light.eot');
  src: url('COOP-Light.eot?#iefix') format('embedded-opentype'),
    url('COOP-Light.woff2') format('woff2'),
    url('COOP-Light.woff') format('woff'),
    url('COOP-Light.ttf') format('truetype'),
    url('COOP-Light.svg#COOP-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}
