// select box modal
.select-box-modal {
  position: fixed;
  background: rgba(66, 66, 66, 0.7);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  .select-box-container {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 95%;
    max-width: 400px;
    margin: auto;
    background: #faf8f5;
    .select-box-content {
      padding: 20px 5px 25px 5px;
      .select-box-title {
        @include font($f: "'COOP', sans-serif", $s: 1rem, $w: $boldweight);
        text-align: center;
        color: #333333;
        margin-bottom: 20px;
      }
    }
    .select-box-buttons {
      border-top: 2px solid #bcbcbc;
      .select-btn {
        border-right: 2px solid #bcbcbc;
      }
      .select-btn,
      .cancel-btn {
        @include font($f: "'COOP', sans-serif", $s: 0.875rem, $w: $boldweight);
        display: inline-block;
        text-align: center;
        color: $red;
        padding: 15px;
        width: 49.5%;
        line-height: 17px;
        box-sizing: border-box;
        cursor: pointer;
      }
    }
  }
}
