%item {
  @extend %flexcontainer;
  padding: 5px;
  border-bottom: 1px solid $lightgrey;
  align-items: center;
  cursor: pointer;
  background: $white;
  position: relative;

  .item-image {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    background-size: cover;
    overflow: hidden;
  }

  .item-detail {
    width: calc(100% - 115px);
    margin-left: 10px;

    &.no-img {
      width: calc(100% - 35px);
    }

    p {
      margin-bottom: 5px;
    }

    .item-date {
      @include font($s: 0.65rem);
    }

    .item-title {
      @include font($s: 0.9rem, $f: $serif-font);
      margin-bottom: 5px;
      display: inline-block;
    }

    .item-shared-option {
      .item-shared-option-name {
        font-family: 'COOP';
        font-size: 14px;
        font-weight: 600;
        color: $lightergrey;
      }

      span:not(:first-child)::before {
        content: ', ';
      }
    }

    .item-desc {
      margin-bottom: 0;
    }
  }

  //item-detail
  .link-arrow {
    width: 25px;
    text-align: center;
    font-size: 15px;

    .sort-buttons {
      i {
        margin: 5px 0;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }

  &.updated-item {
    background: lighten($blue, 55%);
  }
}

.list-component {
  //background: #FFFFFF;
  &.app-page{
    z-index: 10;
  }

  .page-container {
    padding-top: 43px;
    @media #{$desktop,$tabletlandscape} {
      max-width: 768px;
      margin: auto;
    }

    .item-list {
      margin-bottom: 15px;

      .item {
        @extend %item;
      }

      //list-item
    }
  }

  //page-container
  .search-box-container {
    position: relative;

    .search-box {
      padding-top: 5px;
      border-bottom: 1px solid $lightgrey;

      .search-button {
        position: absolute;
        top: 12px;
        cursor: pointer;
      }

      .right-search {
        font-size: 20px;
        right: 15px;
      }

      .left-close {
        font-size: 20px;
        right: 40px;
      }
    }
  }
  .search-containers {
    position: relative;
    width: 85% ;
    border: 1px solid #BDBDBD;
    background-color: #fff;
    border-radius: 5px;
    .search-box {
      padding-top: 5px;
      border-bottom: 1px solid $lightgrey;

      .search-button {
        position: absolute;
        top: 12px;
        cursor: pointer;
      }

      .right-search {
        right: 15px;
      }

      .left-close {
        right: 35px;
      }
    }
  }
}

.list-page-with-search {
  .page-container {
    padding-top: 90px;
  }
}

.item.gu-mirror {
  @extend %item;
  border: 1px solid $lightgrey;
}

//list-component
.tabs {
  @extend %flexcontainer;
  @include position($p: relative);
  border-bottom: 1px solid $lightgrey;

  hr {
    @extend %hoverline;
    left: 100%;
  }

  li {
    @include font($w: $boldweight, $s: 0.8rem);
    position: relative;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
  }

  &.two-col {
    hr {
      width: 50%;
    }

    li {
      width: 50%;

      &.tab-first.active ~ hr {
        left: 0;
      }

      &.tab-second.active ~ hr {
        left: 50%;
      }
    }
  }

  &.three-col {
    hr {
      width: 33.333%;
    }

    li {
      width: 33.333%;

      &.tab-first.active ~ hr {
        left: 0;
      }

      &.tab-second.active ~ hr {
        left: 33.333%;
      }

      &.tab-third.active ~ hr {
        left: 66.666%;
      }
    }
  }

  &.four-col {
    hr {
      width: 25%;
    }

    li {
      width: 25%;

      &.tab-first.active ~ hr {
        left: 0;
      }

      &.tab-second.active ~ hr {
        left: 25%;
      }

      &.tab-third.active ~ hr {
        left: 50%;
      }

      &.tab-fourth.active ~ hr {
        left: 75%;
      }
    }
  }

  &.five-col {
    hr {
      width: 20%;
    }

    li {
      width: 20%;

      &.tab-first.active ~ hr {
        left: 0;
      }

      &.tab-second.active ~ hr {
        left: 20%;
      }

      &.tab-third.active ~ hr {
        left: 40%;
      }

      &.tab-fourth.active ~ hr {
        left: 60%;
      }

      &.tab-fifth.active ~ hr {
        left: 80%;
      }
    }
  }
}

.big-add-btn {
  @include position($p: fixed, $b: 70px, $r: 20px);
  @include font($s: 1rem);
  cursor: pointer;
  background: $red;
  color: $white;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  z-index: 20;
  box-shadow: 0 0 16px -1px rgba(0, 0, 0, 0.55);
}

.big-add-btn-left {
  @extend .big-add-btn;
  left: 20px;
  right: 0px;
}

.add-actions-container {
  @include position($p: fixed, $l: 0);
  padding: 10px;
  width: 100%;
  min-height: 120px;
  z-index: 21;
  background: $red;
  color: $white;
  text-align: center;

  h4 {
    @include font($f: $serif-font, $w: $boldweight, $s: 1rem);
    margin-bottom: 10px;
  }

  .close-btn {
    @include position($p: absolute, $t: 10px, $r: 10px);
    @include font($s: 1rem);
    cursor: pointer;
    z-index: 2;
  }

  ul {
    margin-top: 20px;

    li {
      a {
        display: block;
        text-align: center;
        padding: 10px;
        margin-bottom: 5px;
        border: 1px solid $white;
        border-radius: 3px;
        color: $white;
        cursor: pointer;
      }
    }
  }
}

// custom radio button
.radio-title {
  margin: 10px 0px;
}

.options {
  padding: 0px 5px;
  margin: 0px 15px;
}

.radio-options {
  width: auto;
  display: inherit;
  margin: 0px auto 10px auto;
}

input[type='radio'] {
  float: left;
}

.container {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  border: 1px solid $red;
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border-radius: 50%;
}

.container:hover input ~ .checkmark {
  background-color: $red;
}

.container input:checked ~ .checkmark {
  background-color: $red;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  top: 4px;
  left: 4px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: white;
}

.empty-message {
  @include font($s: 0.7rem, $f: $serif-font);
  text-align: center;
  margin: 10px 0px;
}
