// MEDIA QUERIES
$smallmobile: 'screen and (max-width: 450px)';
$mobile: 'screen and (max-width: 767px)';
$tablet: 'screen and (max-width: 768px)';
$sm-break: 'screen and (min-width: 640px)';
$tabletlandscape: 'screen and (min-width: 769px) and (max-width: 1024px)';
$mobiledevice: 'screen and (max-width: 1024px)';
$desktop: 'screen and (min-width: 1025px)';
$smalldesktop: 'screen and (min-width: 1025px) and (max-width: 1680px)';
$largedesktop: 'screen and (min-width: 1681px)';
$verylargedesktop: 'screen and (min-width: 2101px)';
$highdensity: 'only screen and (-webkit-min-device-pixel-ratio: 1.5)',
'only screen and (min--moz-device-pixel-ratio: 1.5)',
'only screen and (-o-min-device-pixel-ratio: 3/2)',
'only screen and (min-device-pixel-ratio: 1.5)';
$mobilelandscape: 'only screen and (orientation: landscape) and (min-device-width: 319px) and (max-device-width: 767px)';
$notMobileScreen: 'screen and (min-width: 768px)';

// Global Colors
$white: #ffffff;
$offwhite: #faf8f5;
$black: #000000;
$lightgrey: rgba(239, 239, 239, 0.3);
$grey: rgba($black, 0.5);
$darkgrey: #2b2b2b;
$blue: #3b5998;
$lightblue: #28cdfb;
$red: #d10a11;
$gray:#4F4F4F;
$yellow: #f3c130;
$darkgreen: #56891a;
$green: #33ab4a;
$purple: #7c00ff;
$danger: lighten($red, 20%);
$maincolor: #1e1313;
$lightred: rgba($red, 0.5);
$orange: #f0ad4e;
$previewgrey: #8e8e8e;
$previewbordergrey: #cccccc;
$disablegrey: #8e8e8e;
$lightergrey: #8e8e8e;
$previewblack: #121212;
$previewyellow: #f5be05;
$darkergrey: #7d7c7a;
$img-dummy-grey: #bdbdbd;
$urgent-red: #d10a11;
$imp-orange: #ff9635;
$normal-blue: #59cefa;
$slightdarkgrey: #c6c6c6;
$btnblack: #333333;
$fbblue: #1877f2;
$coopdarkgrey: #7f7f7f;
$successgreen: #12872a;
$coopred: #c31414;
$mflred: rgba($red, 0.1);
$previewblue: #4ba5aa;
$cooppreviewgrey: #808080;
$coopgreen: #00aa46;

//Typography
$base-font: 'Inter', sans-serif;
$secondary-font: null;
$headings-font: null; //Add fonts if needed
$serif-font: 'COOP', serif;
$font-size-base: 12px;
$thinweight: 300;
$normaladjustedweight: 500;
$normalweight: 400;
$slightboldweight: 600;
$boldweight: 700;
$heavyweight: 800;
$extrabold: 900;
$headings: h1 h2 h3 h4 h5 h6;
$font-size-upper: $font-size-base * 3;
$font-size-dec: 6px;
