/* You can add global styles to this file, and also import other style files */
@import './app/styles/import';
@import './app/styles/common/reset';
@import './app/styles/common/base';
@import './app/styles/common/global';
@import './app/styles/common/forms';
@import './app/styles/common/listpage';
@import './app/styles/common/modal';

// Components
@import './app/styles/components/login';
@import './app/styles/components/coop-fonts';
@import './app/styles/components/notification';
@import './app/styles/components/accordion';

$mbsc-ios-accent: $red;
$mbsc-material-accent: $red;
$mbsc-windows-accent: $red;


@import "@mobiscroll/angular/dist/css/mobiscroll.scss";
