//MIXINS
@mixin transition($range: all, $time: 0.3s, $ease: ease-in-out, $delay: null) {
  transition: $time $range $ease $delay;
}
@mixin transdelay($time: 1s) {
  transition-delay: $time;
}
@mixin translator($x: 0, $y: 0) {
  transform: translate($x, $y);
}
@mixin placeholder {
  &::-moz-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}
@mixin font($f: null, $s: null, $st: null, $w: null) {
  font-family: $f;
  font-size: $s;
  font-style: $st;
  font-weight: $w;
}
@mixin text($t: null, $d: null, $a: null) {
  text-transform: $t;
  text-align: $a;
  text-decoration: $d;
}
@mixin position($p: null, $t: null, $r: null, $b: null, $l: null) {
  position: $p;
  top: $t;
  right: $r;
  bottom: $b;
  left: $l;
}
@mixin col($columns, $guttervalue) {
  @media #{$desktop,$tabletlandscape} {
    float: left;
    display: block;
    margin-right: $guttervalue;
    $totalgutter: ($columns - 1) * $guttervalue;
    $gutter: $totalgutter / $columns;
    width: calc(100% / #{$columns} - #{$gutter});
    &:nth-child(#{$columns}n + #{$columns}) {
      margin-right: 0;
      &:after {
        content: '';
        width: 100%;
        clear: both;
        display: block;
      }
    }
  }
  @media #{$tablet} {
    width: 100%;
  }
}
// *** Usage ***
// .col-two {
//   @include col (2,20px);
// }

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}
@mixin keyframes($animation-name) {
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  animation: #{$str};
}

// *** Usage ***
// @include keyframes(slide-down) {
//   0% { opacity: 1; }
//   90% { opacity: 0; }
// }
//
// .element {
//   width: 100px;
//   height: 100px;
//   background: black;
//   @include animation('slide-down 5s 3');
// }
//
@mixin wrapper($width) {
  max-width: $width;
  width: 100%;
  margin: auto;
}

@mixin overlay($bg, $op) {
  position: relative;
  &:before {
    content: '';
    @include position($p: absolute, $t: 0, $l: 0);
    width: 100%;
    height: 100%;
    z-index: 0;
    background: rgba($bg, $op);
  }
  > * {
    position: relative;
    z-index: 2;
  }
}
@mixin fixed-class($top, $z-index) {
  position: fixed !important;
  top: $top;
  width: 100%;
  max-width: 768px;
  z-index: $z-index;
  background: $offwhite;
}
@mixin fixed-class-wrapper {
  position: relative;
  width: 100%;
  max-width: 768px;
  margin: auto;
}
