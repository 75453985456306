input:-webkit-autofill,
{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

::placeholder {
  color: #A8A8A8 !important;
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='time'],
input[type='date'],
input[type='number'],
input[type='tel'],
.input-date-time,
select,
.ck-editor__editable_inline,
textarea {
  @include transition();
  @include font($s: 16px);
  appearance: none;
  position: relative;
  width: 100%;
  padding: 10px;
  border: 1px solid #C5C5C7;
  border-radius: 8px;
  margin-bottom: 0;
  background: #f5f5f5;

}

textarea
{
  height: 120px;
  resize: none;
  font-family: inherit;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.ck-rounded-corners .ck.ck-toolbar, .ck.ck-toolbar.ck-rounded-corners {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.ck-editor__editable_inline {
  p {
    margin: 0;
  }

  a {
    font-weight: $boldweight;
  }
}

coop-text-editor {
  width: 100%;
}

coop-input-mask {
  width: 100%;
}

input {
  font-family: inherit;
}

.ck.ck-content ul,
.ck.ck-content ul li {
  list-style-type: inherit;
}

.ck.ck-content ul,
.ck.ck-content ol {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}

div.editor-content {
  p {
    margin-bottom: 0;

    a {
      font-weight: $boldweight;
    }
  }

  ol,
  ul {
    padding-left: 40px;
  }
}

.login-btn {
  @include transition();
  font-size: 16px;
  letter-spacing: -0.02px;
  line-height: 19.26px;
  appearance: none;
  border: 0;
  cursor: pointer;
  width: 100%;
  text-align: center;
  padding: 11px 20px;
  position: relative;
  border-radius: 90px;
  display: block;
  margin-bottom: 10px;
  font-weight: 700;

  &.btn-black-login {
    background-color: #000000;
    color: $white;
    //border: 1px solid $red;
  }
  &.btn-black-login :hover {
    background-color: #000000;
    color: $white;
    //border: 1px solid $red;
  }

  &.btn-white-login {
    background-color: #FFFFFF;
    color: $black;
    border: 2px solid $black;
  }

  &.btn-delete {
    background-color: #DA291C;
    color: $white;
  }
}

.btn {
  @include transition();
  @include font($s: 0.8rem, $w: $normaladjustedweight);
  appearance: none;
  border: 0;
  cursor: pointer;
  width: 100%;
  text-align: center;
  padding: 11px 20px;
  position: relative;
  border-radius: 8px;
  display: block;
  margin-bottom: 10px;


  &.btn-red {
    background: $red;
    color: $white;
    border: 1px solid $red;
  }

  &.btn-white {
    background: $white;
    color: $red;
    border: 1px solid $red;
  }

  &.btn-lightRed {
    background: rgba($red, 0.10);
    color: $red;
    border: 1px solid $red;
  }

  &.btn-small {
    width: 140px;
  }

  &.btn-red:hover {
    background: $red;
    color: $white;
    border: 1px solid $red;
    box-shadow: 0 8px 6px -6px $gray;
  }

  &.btn-red:focus {
    background: $red;
    color: $white;
    border: 1px solid $red;
    //box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &.btn-green {
    background: $darkgreen;
    color: $white;
    border: 1px solid $green;
  }

  &.btn-blue {
    background: $blue;
    color: $white;
    border: 1px solid $blue;
  }

  &.btn-grey {
    background: $grey;
    color: $lightgrey;
    border: 1px solid $grey;
  }

  &.btn-orange {
    background: $orange;
    color: $white;
    border: 1px solid $orange;
  }

  &.btn-black {
    background: $btnblack;
    color: $white;
    border: 1px solid $btnblack;
  }

  &.btn-border-red {
    border: 1px solid $red;
    color: $red;
    background: transparent;
  }

  &.btn:hover {
    box-shadow: 0 8px 6px -6px $gray;
  }

  &.btn-border-red:hover {
    box-shadow: 0px 8px 6px -6px rgba(0, 0, 0, .10);
  }

  &.btn:active {
    box-shadow: inset 0 0 10px $gray;
  }

  &.btn-border-red:active {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, .25);
  }

  &.btn-border-white {
    border: 1px solid $white;
    color: $white;
  }

  &.btn-bottom {
    margin: 0;
  }

  &.btn-no-round {
    border-radius: 0;
  }

  &.btn-medium {
    max-width: 200px;
    margin: auto;
  }

  &.btn-disabled {
    background: #D9D8D8;
    color: #B4B5B5;
    pointer-events: none;
  }

  &.btn-hide {
    display: none;
  }

  &.btn-with-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

.btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

//.btn-container{
//  justify-content: end;
//  &.btn-select{
//    display:inline-block;
//  }
//}

.form-title {
  @include font($s: 1.4rem, $w: $thinweight);
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 10px;
}

.form-subtitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.4px;
  text-align: left;
  width: 100%;
  margin-bottom: 10px;
}

.form-group {
  margin-bottom: 20px;
  position: relative;

  //&:last-child {
  //  margin-bottom: 10px;
  //}

  label {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    letter-spacing: -0.02px;
    //margin-bottom: 8px;
    color: #1c1c1c;
    display: block;

    span {
      @include font($w: $thinweight);
    }

    &.switch {
      position: relative;
      display: inline-block;
      width: 54px;
      height: 28px;

      .toggle {
        @include position($p: absolute, $t: 0, $l: 0, $b: 0, $r: 0);
        @include transition();
        background: $white;
        border: 1px solid lighten($grey, 30%);

        &:before {
          @include position($p: absolute, $l: -1px, $b: 0px);
          @include transition();
          content: '';
          height: 26px;
          width: 26px;
          background: $white;
        }

        &.round {
          border-radius: 34px;

          &:before {
            border-radius: 50%;
            border: 1px solid lighten($grey, 30%);
          }
        }
      }

      input {
        display: none;

        &:checked + .toggle {
          background: $green;
          border-color: transparent;
        }

        &:focus + .toggle {
          box-shadow: 0 0 1px #2196f3;
        }

        &:checked + .toggle:before {
          @include translator($x: 27px);
          border-color: transparent;
        }
      }
    }
  }

  .select-box {
    position: relative;
    width: 100%;
    border: 1px solid $lightgrey;

    &:after {
      @include position($p: absolute, $t: 15px, $r: 10px);
      content: '\e64b';
      font-family: 'themify', sans-serif;
    }

    select,
    .select-box-div {
      appearance: none;
      width: 100%;
      padding: 10px;
      border: 0;
      border-radius: 3px;
      background: $white;

      &.disabled {
        pointer-events: none;
        background: $lightgrey;
      }
    }
  }

  span.error-message {
    @include transition();
    display: block;
    text-align: center;
    color: $danger;
    margin: 5px 0 10px;
    width: 100%;
  }

  &.switches {
    align-items: center;

    label:not(.switch) {
      width: calc(100% - 65px);
    }
  }

  &.error {
    label {
      color: $danger;
    }

    input,
    textarea,
    coop-text-editor > ckeditor > div,
    .message-edit-area,
    .select-box {
      border-color: $danger;
    }
  }
}

.form-message {
  @extend %flexcontainer;
  width: 100%;
  margin-bottom: 25px;
  align-items: center;
  padding: 0 10px;

  .form-message-icon {
    font-size: 1.1rem;
    text-align: center;
  }

  .form-message-text {
    padding: 10px;
    text-transform: uppercase;
    width: calc(100% - 25px);

    p {
      font-size: 0.8rem;
      margin-bottom: 0;
    }
  }

  &.error {
    background: $danger;
    color: $white;
  }
}

.form-fields {
  background: $white;
  padding: 10px 15px 0;
  margin-bottom: 10px;
  border: solid $lightgrey;
  border-width: 1px 0 1px 0;
}

.form-style-one {
  .form-group {
    input[type='text'],
    input[type='email'],
    input[type='password'],
    input[type='time'],
    input[type='date'],
    textarea {
      border-width: 0;
      border-bottom-width: 1px;
    }
  }
}

#pricedesc,
.pricedesc {
  &:read-only {
    background: $lightgrey;
  }
}

.message-edit-area {
  position: relative;
  width: 100%;
  padding: 10px;
  border: 1px solid $lightgrey;
  border-radius: 8px;
  margin: 5px 0;
  text-align: left;
  background: $white;
  //height: 280px;
  textarea {
    border: 0;
    margin: 0;
    padding: 0;
  }

  .static-message {
    white-space: pre-line;
    width: 100%;
    display: block;
  }
}

.share-container {
  padding: 10px 0;

  .share-list {
    border-top: 1px solid $lightgrey;
    background: $white;
    margin-bottom: 20px;

    li {
      @include position($p: relative);
      padding: 10px 15px;
      border-bottom: 1px solid $lightgrey;
      cursor: pointer;

      .check {
        @include position($p: absolute, $t: 50%, $r: 15px);
        @include translator($y: -50%);
        color: $green;

        &.icon-check-box {
          padding: 5px;
          border: 1px solid rgba($black, 0.2);
          border-radius: 5px;
          height: 32px;
          width: 32px;
        }
      }
    }
  }
}

.message-box {
  @include position($p: fixed, $t: 0, $l: 50%);
  @include translator($x: -50%);
  z-index: 1000;
  width: 100%;
  max-width: 768px;
  padding: 10px;
  font-size: 0.9rem;

  .title {
    font-weight: $boldweight;
    width: 80%;
  }

  .option {
    width: 20%;
    text-align: right;

    .option-btn {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  &.confirm {
    background: $green;
    color: $white;
    @extend %flexcontainer;

    .option .option-btn {
      color: $white;
    }
  }
}

.tab-container {
  //padding: 20px 15px 0 15px;
  //border-bottom: 1px solid #C5C5C7;

  .tab-list {

    .tab-option {
      font-size: 16px;
      font-weight: 600;
      color: #1c1c1c;
      line-height: 19.36px;
      letter-spacing: -0.4px;
      text-align: center;
      border-radius: 4px;
      cursor: pointer;

      .share-popup-btn {
        color: $grey;
        font-size: 14px;
      }
    }

    .active {
      display: inline-block;
      float: left;
      //background-color: ;
      color: $black;
      text-align: center;
      border-radius: 4px;
      cursor: pointer;
    }

    .inactive {
      color: $lightgrey;
    }
  }

  .single-channel {

  }

  .tab-list.error,
  .tab-list.error li {
    border-color: $lightred;
    color: $lightred;
  }
}

.preview {
  margin-bottom: 25px;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-wrapper p {
  margin-bottom: 0px;
  margin-left: 10px;
}

.ck-body-wrapper {
  display: none;
}

.ck-editor__editable_inline {
  height: 150px;
}

.toggle-button-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #BDBDBD;
  //background-color: #FFFFFF;
  padding: 15px 15px;
  border-radius: 5px;

  .toggle-label, label{
    margin: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #D1D1D6;
  ;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 23px;
    width: 24px;
    left: 2px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  input:checked + .slider {
    background-color: #00D082;
  }


  input:focus + .slider {
    box-shadow: 0 0 1px #00D082;
  }
  input:checked + .slider:before {
    transform: translateX(25px);
  }
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
