@font-face {
  font-family: 'quickcoop-icon';
  src:  url('./quickcoopicons.eot?w4klus');
  src:  url('./quickcoopicons.eot?w4klus#iefix') format('embedded-opentype'),
    url('./quickcoopicons.ttf?w4klus') format('truetype'),
    url('./quickcoopicons.woff?w4klus') format('woff'),
    url('./quickcoopicons.svg?w4klus#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'quickcoop-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-gallery-remove:before {
  content: "\e982";
}
.icon-gallery-add:before {
  content: "\e97b";
}
.icon-gallery-add-filled:before {
  content: "\e97c";
}
.icon-gallery-edit:before {
  content: "\e97d";
}
.icon-gallery-edit-filled:before {
  content: "\e97e";
}
.icon-gallery-remove-filled:before {
  content: "\e97f";
}
.icon-video-play-filled:before {
  content: "\e980";
}
.icon-video-play:before {
  content: "\e981";
}
.icon-moneys:before {
  content: "\e900";
}
.icon-close-square-filled:before {
  content: "\e972";
}
.icon-arrow-right-straight:before {
  content: "\e973";
}
.icon-close-square:before {
  content: "\e974";
}
.icon-danger-filled:before {
  content: "\e975";
}
.icon-danger:before {
  content: "\e976";
}
.icon-more-filled:before {
  content: "\e977";
}
.icon-more-triangle-filled:before {
  content: "\e978";
}
.icon-more-triangle:before {
  content: "\e979";
}
.icon-more:before {
  content: "\e97a";
}
.icon-filter-filled:before {
  content: "\e971";
}
.icon-filter:before {
  content: "\e970";
}
.icon-arrow-left:before {
  content: "\e96f";
}
.icon-add-circle-filled:before {
  content: "\e901";
}
.icon-add-circle:before {
  content: "\e902";
}
.icon-add-filled:before {
  content: "\e903";
}
.icon-add:before {
  content: "\e904";
}
.icon-archive-add-filled:before {
  content: "\e905";
}
.icon-archive-add:before {
  content: "\e906";
}
.icon-arrow-down:before {
  content: "\e907";
}
.icon-arrow-right:before {
  content: "\e908";
}
.icon-arrow-up-down:before {
  content: "\e909";
}
.icon-arrow-up:before {
  content: "\e90a";
}
.icon-bag-cross-filled:before {
  content: "\e90b";
}
.icon-bag-cross:before {
  content: "\e90c";
}
.icon-bag-filled:before {
  content: "\e90d";
}
.icon-bag-tick-filled:before {
  content: "\e90e";
}
.icon-bag-tick:before {
  content: "\e90f";
}
.icon-bag:before {
  content: "\e910";
}
.icon-book-filled:before {
  content: "\e911";
}
.icon-book:before {
  content: "\e912";
}
.icon-calendar-add-filled:before {
  content: "\e913";
}
.icon-calendar-add:before {
  content: "\e914";
}
.icon-calendar-filled:before {
  content: "\e915";
}
.icon-calendar:before {
  content: "\e916";
}
.icon-call-calling-filled:before {
  content: "\e917";
}
.icon-call-calling:before {
  content: "\e918";
}
.icon-candle-filled:before {
  content: "\e919";
}
.icon-candle:before {
  content: "\e91a";
}
.icon-card-tick-filled:before {
  content: "\e91b";
}
.icon-card-tick:before {
  content: "\e91c";
}
.icon-chart-circular-filled:before {
  content: "\e91d";
}
.icon-chart-circular:before {
  content: "\e91e";
}
.icon-chart-filled:before {
  content: "\e91f";
}
.icon-chart:before {
  content: "\e920";
}
.icon-clock-filled:before {
  content: "\e921";
}
.icon-clock:before {
  content: "\e922";
}
.icon-close-circle-filled:before {
  content: "\e923";
}
.icon-close-circle:before {
  content: "\e924";
}
.icon-data-filled:before {
  content: "\e925";
}
.icon-data:before {
  content: "\e926";
}
.icon-discount-shape-filled:before {
  content: "\e927";
}
.icon-discount-shape:before {
  content: "\e928";
}
.icon-document-copy-filled:before {
  content: "\e929";
}
.icon-document-copy:before {
  content: "\e92a";
}
.icon-edit-filled:before {
  content: "\e92b";
}
.icon-edit:before {
  content: "\e92c";
}
.icon-facebook-filled:before {
  content: "\e92d";
}
.icon-facebook:before {
  content: "\e92e";
}
.icon-global-edit-filled:before {
  content: "\e92f";
}
.icon-global-edit:before {
  content: "\e930";
}
.icon-global-filled:before {
  content: "\e931";
}
.icon-global:before {
  content: "\e932";
}
.icon-graph-filled:before {
  content: "\e933";
}
.icon-graph:before {
  content: "\e934";
}
.icon-home-filled:before {
  content: "\e935";
}
.icon-home:before {
  content: "\e936";
}
.icon-info-circle-filled:before {
  content: "\e937";
}
.icon-info-circle:before {
  content: "\e938";
}
.icon-like-tag-filled:before {
  content: "\e939";
}
.icon-like-tag:before {
  content: "\e93a";
}
.icon-link:before {
  content: "\e93b";
}
.icon-location-filled:before {
  content: "\e93c";
}
.icon-location:before {
  content: "\e93d";
}
.icon-message-filled:before {
  content: "\e93e";
}
.icon-message-search-filled:before {
  content: "\e93f";
}
.icon-message-search:before {
  content: "\e940";
}
.icon-message-tick-filled:before {
  content: "\e941";
}
.icon-message-tick:before {
  content: "\e942";
}
.icon-message-time-filled:before {
  content: "\e943";
}
.icon-message-time:before {
  content: "\e944";
}
.icon-message:before {
  content: "\e945";
}
.icon-mirroring-screen-filled:before {
  content: "\e946";
}
.icon-mirroring-screen:before {
  content: "\e947";
}
.icon-mobile-filled:before {
  content: "\e948";
}
.icon-mobile:before {
  content: "\e949";
}
.icon-moneys-filled:before {
  content: "\e94a";
}
.icon-play-filled:before {
  content: "\e94b";
}
.icon-play:before {
  content: "\e94c";
}
.icon-printer-filled:before {
  content: "\e94d";
}
.icon-printer:before {
  content: "\e94e";
}
.icon-profile-users-filled:before {
  content: "\e94f";
}
.icon-profile-users:before {
  content: "\e950";
}
.icon-refresh-filled:before {
  content: "\e951";
}
.icon-refresh-intact-filled:before {
  content: "\e952";
}
.icon-refresh-intact:before {
  content: "\e953";
}
.icon-refresh:before {
  content: "\e954";
}
.icon-rulerpen-filled:before {
  content: "\e955";
}
.icon-rulerpen:before {
  content: "\e956";
}
.icon-save-filled:before {
  content: "\e957";
}
.icon-save:before {
  content: "\e958";
}
.icon-search-circular-end:before {
  content: "\e959";
}
.icon-search-circular-end-filled:before {
  content: "\e95a";
}
.icon-search-normal-filled:before {
  content: "\e95b";
}
.icon-search-normal:before {
  content: "\e95c";
}
.icon-sms-filled:before {
  content: "\e95d";
}
.icon-sms:before {
  content: "\e95e";
}
.icon-stop-filled:before {
  content: "\e95f";
}
.icon-stop:before {
  content: "\e960";
}
.icon-tag-filled:before {
  content: "\e961";
}
.icon-tag-user-filled:before {
  content: "\e962";
}
.icon-tag-user:before {
  content: "\e963";
}
.icon-tag:before {
  content: "\e964";
}
.icon-task-square-filled:before {
  content: "\e965";
}
.icon-task-square:before {
  content: "\e966";
}
.icon-tick-circle-filled:before {
  content: "\e967";
}
.icon-tick-circle:before {
  content: "\e968";
}
.icon-tick-square-filled:before {
  content: "\e969";
}
.icon-tick-square:before {
  content: "\e96a";
}
.icon-video-filled:before {
  content: "\e96b";
}
.icon-video:before {
  content: "\e96c";
}
.icon-volume-high-filled:before {
  content: "\e96d";
}
.icon-volume-high:before {
  content: "\e96e";
}
