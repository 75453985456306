@import '../../styles/import';

// order list
.order-container {
  .accordion.expanded {
    .accordion-header .icon span {
      transform: rotate(180deg);
    }

    .accordion-content {
    }
  }

  .accordion {
    display: block;
    width: 100%;
    box-shadow: -0.0816865px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 13px;
    margin-bottom: 20px;
    background: $white;

    .accordion-header {
      display: grid;
      grid-template-columns: 1fr 44px;
      align-items: center;
      height: 55px;

      .title {
        display: flex;
        align-items: center;
        margin: 10px 0 10px 20px;

        p {
          font-weight: $boldweight;
          font-size: 14px;
          color: $btnblack;
          margin: 0;

          span[sn] {
            margin-right: 10px;
          }

          .product-status {
            margin-left: 10px;
            position: relative;
            top: 2px;

            img {
              height: 14px;
            }

            .ti-check {
              color: $green;
              font-weight: $boldweight;
            }

            .ti-close {
              color: $red;
              font-weight: $boldweight;
            }
          }
        }
      }

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        height: 100%;
        width: 100%;

        span.ti-angle-double-down {
          transition: transform 250ms ease-in-out;
        }
      }
    }

    .accordion-content {
      height: 0;
      padding: 0 20px;
      overflow: hidden;
      transition: height 250ms ease-in-out;
    }
  }

  .order-list {
    .order {
      margin: 0 10px 10px 0;

      p,
      a {
        font-size: 12px;
        font-weight: $normalweight;
        color: $btnblack;
        margin: 5px 0;
      }

      div[phonenumber] {
        font-size: 12px;
        font-weight: $normalweight;
        color: $btnblack;
        margin: 5px 0;

        img {
          margin: 0 10px;
        }

        a {
          text-decoration: underline;
          color: $btnblack;
          cursor: pointer;
        }
      }

      div.fb-comment-link {
        display: flex;
        align-items: center;
        font-size: 1rem;

        a {
          text-decoration: underline;
          color: $btnblack;
          cursor: pointer;
        }
      }

      p.product-label {
        margin-bottom: 0;
      }

      p.product-name {
        font-weight: $boldweight;
        margin-top: 0;
      }

      .order-approve-reject {
        display: flex;
        align-items: center;

        & > * {
          margin-right: 5px;
        }

        div[approve],
        div[reject] {
          display: flex;
          align-items: center;
          width: 130px;
          height: 20px;
          border-radius: 5px;
          justify-content: center;
          font-weight: $boldweight;
          color: $white;
          cursor: pointer;
          line-height: 14.63px;
        }

        div[approve] {
          background: $coopgreen;
        }

        div[reject] {
          background: $btnblack;
        }

        input {
          border-radius: 2px;
          background: $white;
          border: 1px solid $black;
          font-size: 12px;
          color: $btnblack;
          text-align: center;
          height: 20px;
          width: 33px;
          max-width: 40px;
          margin-bottom: 0;
        }

        input + span {
          font-weight: $boldweight;
          font-size: 12px;
          color: $btnblack;
        }

        p:first-child {
          font-weight: $boldweight;
        }

        .approved {
          font-weight: $boldweight;
          color: $coopgreen;
        }

        .rejected {
          font-weight: $boldweight;
          color: $coopred;
        }
      }

      .order-approve-message {
        margin-top: 5px;
        color: $coopred;
        font-size: 12px;
      }
    }
  }

  .order-status {
    display: flex;
    width: 100%;
    margin-top: 20px;

    .picked,
    .deleted {
      font-weight: $boldweight;
      font-size: 14px;
      margin: 0;
    }

    .picked {
      color: $coopgreen;
    }

    .deleted {
      color: $coopred;
    }

    div[accept],
    div[cancel] {
      display: flex;
      align-items: center;
      border-radius: 5px;
      justify-content: center;
      font-weight: $boldweight;
      color: $white;
      height: 32px;
      cursor: pointer;

      span {
        font-size: 12px;
        margin-right: 5px;
        font-weight: $boldweight;
      }
    }

    div[accept] {
      background: $coopgreen;
    }

    div[cancel] {
      background: $coopred;
    }

    div:first-child {
      margin-right: 5px;
    }

    div {
      flex: 1;
    }
  }
}

@media #{$mobile} {
  .order-container {
    padding: 0 10px;
  }
}
