// changing the size and padding of default mobiscroll snackbar
.mbsc-snackbar {
  .mbsc-fr-w {
    width: 70%;
    margin: auto;
    .mbsc-fr-c {
      text-align: center;
      .mbsc-snackbar-msg {
        padding: 0;
        line-height: 1;
        white-space: pre-wrap;
      }
    }
  }
}
