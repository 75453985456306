@import 'src/app/styles/import';

.app-login {
  @include position($p: relative);
  padding: 40px 20px;
  height: 100%;
  background: $offwhite;
  z-index: 10;

  .app-logo,
  .login-title,
  .login-intro,
  .coop-logo {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .login-title {
    @include font($s: 1.2rem, $f: $serif-font, $w: $boldweight);
  }

  .login-intro {
    @include font($s: 0.9rem);
  }

  .login-intro {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.4px;
    color: #1c1c1c;
    opacity: 0.4;
  }

  .wrapper {
    height: 100%;
  }

  .login-desc {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.4px;
    opacity: 0.4;

    .login-desc-title {
      font-weight: 700;
    }

    h3 {
      @include font($s: 0.8rem);
      display: block;
    }

    p {
      margin-bottom: 0px;
    }
  }

  form {
    margin-top: 10px;
  }

  .form-container {
    width: 100%;
    max-width: 768px;

    input[type='text'],
    input[type='password'] {
      border-radius: 0;
      background: none;
    }

    @media #{$desktop,$tabletlandscape} {
      @include position($p: absolute, $t: 50%, $l: 50%);
      @include translator($x: -50%, $y: -50%);
      padding: 20px;
      background: $white;
      max-width: 400px;
      box-shadow: 0 0 13px 2px rgba(0, 0, 0, 0.1);
    }

    .login-type {
      padding: 10px 0;

      &.loading {
        height: 200px;
        background: url(/assets/images/loading.svg) center no-repeat;
        background-size: 50px;
      }

      .alt-login {
        color: #d10a11;
        border: 1px solid #d10a11;
        border-radius: 5px;
      }
    }
  }
}

.alternativelogin {
  display: none;

  &.visible {
    display: block;
  }
}
