@import 'variables';
@import 'mixins';
%floatleft {
  float: left;
}

%floatright {
  float: right;
}

%floatnone {
  float: none;
}

%bold {
  @include font($w: $boldweight, $f: $headings-font);
}

%headingstyle {
  @extend %bold;
  line-height: 1.3;
  margin-bottom: 20px;
}

%v-align-middle {
  @include position($p: absolute, $t: 50%, $l: 50%);
  @include translator(-50%, -50%);
}

%bgcover {
  background: center no-repeat;
  background-size: cover;
}

%flexcontainer {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #F2F2F2;
  //flex-wrap: wrap;
}

%hoverline {
  @include position($p: absolute, $b: 0);
  @include transition();
  content: '';
  height: 2px;
  background: $red;
  display: block;
  border: none;
  margin: 0;
}

%flexcenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
