@import 'src/app/styles/import';

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

html.ios {
  --ion-default-font: $base-font;
  background: $offwhite;
}


body {
  @include position($p: relative);
  @include font($f: $base-font, $s: $font-size-base, $w: $normalweight);
  @include transition();
  color: $maincolor;
  background: $offwhite;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  height: 100%;
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);

  &.popup-open {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &.cropper-open {
    overflow: hidden;
    position: fixed;
  }

  &.cropper-open {
    product-list,
    app-calendar {
      position: fixed;
      z-index: 15;
    }
  }

  .mbsc-fr-w {
    h2 {
      padding: 10px 0 !important;
      background-color: $danger;
      color: $white;
      margin: 10px 0 !important;

      i {
        margin-right: 5px;
        font-size: 2rem;
      }
    }

    .mbsc-snackbar-msg {
      text-align: center;
    }

    .mbsc-fr-hdr {
      color: $red;
      font-size: 0.8rem;
      padding: 1.2em 2em !important;
    }

    .mbsc-ltr .mbsc-cal-weeks .mbsc-cal-days {
      margin-left: 1.625em;
    }

    .mbsc-fr-c {
      .mbsc-cal-c {
        .mbsc-cal-weeks {
          .mbsc-cal-body {
            .mbsc-cal-day-picker {
              .mbsc-cal-days-c {
                .mbsc-cal-days {
                  margin-left: 37px;

                  div:first-child::before {
                    content: 'Uge';
                    font-family: 'COOP';
                    font-size: 15px;
                    line-height: 27px;
                    font-weight: bold;
                    color: #b0b0b0;
                    position: absolute;
                    left: 3px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .mbsc-cal-table {
      .mbsc-cal-row {
        .mbsc-cal-week-nr {
          font-family: 'COOP';
          font-weight: bold;
          font-size: 17px;
          line-height: 17px;
          color: #b0b0b0;
        }

        .mbsc-cal-today {
          color: $red;
        }

        .mbsc-selected {
          .mbsc-cal-cell-txt {
            border-color: $red !important;
            background: $red !important;
          }
        }
      }
    }

    .mbsc-cal-tabs-c {
      .mbsc-cal-tabs {
        .mbsc-cal-tab {
          border: 1px solid $red;
          color: $red;
        }

        .mbsc-cal-tab.mbsc-selected {
          background: $red !important;
        }
      }
    }

    .mbsc-fr-btn-cont {
      .mbsc-fr-btn-w.mbsc-fr-btn-s {
        .mbsc-fr-btn {
          border-radius: 0 0 0 0.5em !important;
        }
      }

      .mbsc-fr-btn-w.mbsc-fr-btn-c {
        .mbsc-fr-btn {
          border-radius: 0 0 0.5em 0 !important;
        }
      }
    }

    .mbsc-fr-btn {
      color: $red !important;
      font-weight: $boldweight;
    }
  }

  .mbsc-ios .mbsc-cal-cell:focus .mbsc-cal-cell-txt,
  .mbsc-ios.mbsc-no-touch:not(.mbsc-ev-cal),
  .mbsc-cal-cell:not(.mbsc-disabled):hover,
  .mbsc-cal-cell-txt,
  .mbsc-ios.mbsc-no-touch,
  .mbsc-cal-day:not(.mbsc-disabled),
  .mbsc-cal-day-date:hover,
  .mbsc-ios.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
  .mbsc-ios .mbsc-sc-itm:focus {
    background: rgba(209, 10, 17, 0.3);
  }

  .mbsc-ios.mbsc-datepicker .mbsc-calendar, .mbsc-ios.mbsc-datepicker .mbsc-calendar-slide, .mbsc-ios.mbsc-datepicker .mbsc-calendar-cell {
    padding-bottom: 0 !important;
  }

  // for mobiscroll progress bar
  mbsc-progress {
    label {
      background: none !important;
    }

    .mbsc-input-wrap {
      .mbsc-progress-cont {
        .mbsc-progress-track {
          height: 0.5em;
        }
      }
    }
  }

  .mbsc-progress-bar {
    background: $red !important;
  }

  .mbsc-ios.mbsc-ts .mbsc-sc-lbl {
    color: $red;
  }
}

ion-content {
  //--ion-background-color: $white;
}

// for mbsc timespan and date-time for smaller screens
.mbsc-ts .mbsc-fr-persp .mbsc-fr-scroll .mbsc-fr-popup .mbsc-fr-w,
.mbsc-dt .mbsc-fr-persp .mbsc-fr-scroll .mbsc-fr-popup .mbsc-fr-w {
  min-width: 310px !important;
}

.icon {
  font-size: 1.5rem;
}

.isax-stop {
  font-size: 1.5rem;
}


i.fa,
i[class^='ti-'] {
  font-style: normal;
}

.flex-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .col-two {
    width: 49%;
  }
}

.app-page {
  @include position($p: relative);
  padding-bottom: 70px;
  //z-index: 20;
  min-height: 100%;

  .fixed-header,
  .fixed-header-bug-fix {
    z-index: 5;
    width: 100%;
    background: $white;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);

    .fixed-header-content {
      position: relative;
      @media #{$desktop,$tabletlandscape} {
        max-width: 768px;
        margin: auto;
      }
    }
  }

  .fixed-header {
    position: fixed;
  }

  .page-container {
    @media #{$desktop,$tabletlandscape} {
      max-width: 768px;
      margin: auto;
    }

    &.full-width {
      @media #{$desktop,$tabletlandscape} {
        max-width: 100%;
      }
    }

    .page-intro {
      padding: 10px 0;
      text-align: left;
    }

    .image-cropper {
      #canvas-container {
        .cropper-container {
          margin: auto;
        }
      }
    }
  }

  &.below-footer{
    z-index: 10;
  }
}

.page-with-fixed-header {
  .page-container {
    padding: 43px 15px 43px 15px;
  }
}

.page-with-fixed-header-and-tab {
  .page-container {
    padding: 117px 15px 0 15px;
  }

  #fixed {
    position: fixed;
  }

  .tab-container {
    .channel-selection-btn {
      display: flex;
      justify-content: center;
      border-radius: 4px;
      cursor: pointer;
      background: transparent;
      font-size: 12px;
      line-height: 16.74px;
      letter-spacing: 0.12px;
      color: #1c1c1c;

      span,
      i {
        vertical-align: middle;
        color: #1c1c1c;
        //font-weight: 600;
        display: inline-block;
      }

      i {
        position: relative;
        top: 1px;
        -webkit-transition: ease-in-out 0.5s;
        -moz-transition: ease-in-out 0.5s;
        -ms-transition: ease-in-out 0.5s;
        -o-transition: ease-in-out time;
        transition: ease-in-out 0.5s;
      }

      .show-channel-selection {
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
        -webkit-transition: ease-in-out 0.5s;
        -moz-transition: ease-in-out 0.5s;
        -ms-transition: ease-in-out 0.5s;
        -o-transition: ease-in-out time;
        transition: ease-in-out 0.5s;
      }
    }

    .channel-selection-box {
      //position: absolute;
      z-index: 102;
      width: 100%;
      height: 100vh;
      //background: rgba(0, 0, 0, 0.5);
      left: 0;
    }
  }
}


.channel-selection-overlay-container {
  position: absolute;
  height: 100%;
  min-height: 100vh;
  max-width: 768px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;

  .close-selection {
    position: absolute;
    top: 5px;
    right: 8px;
    color: $black;
    cursor: pointer;
    padding: 5px;
    font-size: 14px;
  }
}

.noevents {
  pointer-events: none;
  opacity: 0.5;
}

.section-title {
  background: $offwhite;
  padding: 10px 0;
  text-align: center;
  @include font($f: $serif-font, $w: $boldweight, $s: 1rem);
  border: solid $lightgrey;
  border-width: 1px 0 1px 0;
  margin-bottom: 0;
}

.flex-column {
  flex-direction: column;
}

.date-time-picker-container {
  @include position($p: fixed, $t: 0, $l: 0);
  width: 100%;
  height: 100%;
  background: rgba($black, 0.6);
  z-index: 1000;
  overflow-y: scroll;

  .dt-wrapper {
    @include position($p: absolute, $t: 50%, $l: 50%);
    @include translator($x: -50%, $y: -50%);
    background: $white;
    @media #{$mobilelandscape} {
      top: 20px;
      @include translator($x: -50%, $y: 0);
    }
  }

  .p-dt-picker {
    width: calc(100% - 2em);

    input {
      margin: 0;
    }

    .ui-datepicker {
      width: 100%;
      min-width: 250px;
      max-width: 400px;

      input {
        margin: 0;
      }

      .ui-timepicker > div a {
        font-size: 25px;
      }

      &.ui-widget {
        .ui-datepicker-calendar td a.ui-state-active {
          background: $red;
        }
      }
    }

    .ui-widget-content {
      border: 0;
      border-radius: 0;
    }
  }
}

.font-class {
  max-height: 26px;
  display: block;
  margin: 0 auto;
  width: auto;
}

.upload-tab-option {
  background: $white;
  @media #{$tablet} {
    margin: 0 -15px;
  }

  li {
    color: $darkergrey;
  }
}


// pintura-editor

.pintura-editor {
  --color-background: 0, 0, 0 !important;
  --color-foreground: 255, 255, 255 !important;
  --color-primary: #d10a11 !important;
  --color-primary-dark: #d10a11 !important;
  --color-primary-text: #fff !important;
  --color-secondary: #03a9f4;
  --color-secondary-dark: #046bbf;

  .PinturaTabPanels
  .PinturaTabPanel
  .PinturaPanel
  .PinturaUtilFooter
  .PinturaShapePresetSelect {
    display: none !important;
  }

  @media #{$notMobileScreen} {
    --font-size: 20px;
  }
}

.PinturaUtilFooter .PinturaControlPanels {
  max-width: 100% !important;
}

.PinturaShapePresetsList .PinturaShapePreset {
  width: 50px !important;
}

.PinturaUtilFooter .PinturaShapePresetsFlat {
  width: 100%;
}

.PinturaUtilFooter .PinturaShapePresetSelect {
  display: none;
}

.PinturaButtonLabel {
  font-size: 0.75rem !important;
}

.PinturaButtonInner {
  padding: 8px 15px;
}

//////////////////////

@media print {
  @page {
    size: A4;
    margin: 0.2in;
  }
  html,
  body {
    height: auto;
  }
  page-footer {
    display: none;
  }
  // hidding unwanted elements for printing
  button,
  .page-action,
  .big-add-button,
  .ti-close,
  .ti-check,
  .not-print,
  .mbsc-fr {
    display: none;
  }
}

.channel-margin-5 {
  margin-right: 5px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.information-section {
  position: absolute;
  bottom: 90px;
  left: 50%;
  transform: translateX(-50%);
  width: 95%;
  z-index: 20;

  .close-icon {
    cursor: pointer;
  }

  .wrapper {
    background: rgba($black, 0.7);
    color: $white;
    border-radius: 5px;
    padding: 10px;
  }

  ul {
    list-style-type: disc !important;
    color: #FFFFFF !important;
    padding-left: 10px !important;
  }

  p {
    text-align: left;
    margin-bottom: 10px;
    font-size: 15px;
  }

  a {
    color: #FFFFFF !important;
  }

}

.remaining {
  position: absolute;
  right: 5px;
}


.price-description-wrapper {
  display: flex;
  align-items: flex-start;
  //justify-content: space-between;
  .form-group.error.label {
    color: #f64b51;
  }

  .price-container {
    display: flex;
    align-items: flex-start;
    flex-basis: 35%;

    span.error-message {
      text-align: start !important;
    }

    .form-group {
      position: unset;
      margin-bottom: 0 !important;

    }


  }

  .price-description-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-basis: 75%;

    .price-description {
      flex-basis: 55%;
    }

    span.error-message {
      text-align: start !important
    }

    .form-group {
      position: unset;
      margin-bottom: 0 !important;
    }
  }

  .price-limit {
    position: relative;
    background-color: #fff;
    height: 100%;
    flex-basis: 30%;
    //margin-right: 15px;
  }
}

.invalid {
  margin-bottom: 50px !important;
}


//tooltipcss

.ng-tooltip {
  position: absolute;
  max-width: 250px;
  font-size: 13px;
  text-align: left;
  color: #333333;
  padding: 10px;
  background: #d9d9d9;
  border-radius: 4px;
  z-index: 1000;
  opacity: 0;
}

.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}

.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: black transparent transparent transparent;
}

.ng-tooltip-show {
  opacity: 1;
}

.label-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title{
    font-size: 18px;
    line-height: 21.78px;
    letter-spacing: -0.02px;
    color: #d10a11;
    margin-bottom: 10px;
    font-weight: 700;
  }
}

.tooltip-icon {
  margin-left: 5px;
  //margin-bottom: 5px;
  cursor: pointer;
}

.tooltip-icon i {
  font-size: 15px !important;
  color: #BDBDBD;
}

.dropdown-icon {
  position: absolute;
  right: 15px;
  top: 12px;
}

.font-16 {
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  letter-spacing: -0.4px;
  margin-bottom: 5px;
}


.section {
  background: $white;
  padding: 30px 0;
  border-bottom: 1px solid #C5C5C7;

  .option-box {
    border: 1px solid #C5C5C7;
    border-radius: 8px;
    background-color: #fbfbfb;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .title-icon-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    ion-icon {
      font-size: 24px;
    }

  }

  .sub-title {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.74px;
    letter-spacing: 0.12px;
    color: #666666;
  }

  .panel-wrap {
    background: #D9D9D9;
    padding-left: 34px;
  }

  #panel,
  #fb-option-panel {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background: $white;
    border-left: 2px solid #4BA5AA;

  }

  .link-group-title {
    margin-bottom: 0;
    padding: 15px 0;
    background: #FAF8F5;
    @include font($w: $thinweight, $s: 16px);
  }

}


.noborder {
  border-bottom: none !important;
}

.options-container {
  border-radius: 8px;
  border: 1px solid #C5C5C7;
  background-color: #fbfbfb;
  padding: 0 15px;

  .option-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid #C5C5C7;
  }

}


// for upload options

.upload-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-top: 24px;
  background-color: #F5F5F5;
  border-radius: 4px;


  .options-list {
    padding: 10px;
    flex-grow: 1;
    font-size: 12px;
    line-height: 16.74px;
    letter-spacing: 0.12px;
    cursor: pointer;
  }

  .active {
    font-weight: 700;
    font-size: 12px;
    line-height: 16.74px;
    letter-spacing: 0.12px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 8px 0 #0000001A;;
    border-radius: 8px;
  }
}


.section-wrapper {
  margin: 32px 0;
  //margin-bottom: 24px;



  .subtitle {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.4px;
    font-weight: 400;
  }

  .jatak-setting {
    font-size: 12px;
    line-height: 16.74px;
    letter-spacing: 0.12px;
    font-weight: 400;
    text-decoration: underline;
  }
}
.title {
  font-size: 18px;
  line-height: 21.78px;
  letter-spacing: -0.02px;
  color: #d10a11;
  margin-bottom: 10px;
  font-weight: 700;
}
.hr {
  margin: 30px 0;
  border-top: 1px solid #C5C5C7;
}

.switch {
  position: relative;
  display: inline-block;
  width: 48px !important;
  height: 27px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #D1D1D6;;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 24px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #00D082;
}


input:focus + .slider {
  box-shadow: 0 0 1px #00D082;
}

input:checked + .slider:before {
  transform: translateX(19px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggle-btn label {
  margin-bottom: 0 !important;
}

.border-15 {
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #C5C5C7;
}

.background-f5 {
  background-color: #F5F5F5;
}

.error-message {
  text-align: center;
  color: red;
  margin-top:10px;
}

.disabled {
  pointer-events: none;
  opacity: 40%!important;
}


.next-prev {
  margin-left: 10px;

  ion-icon {
    font-size: 18px;
    cursor: pointer;
  }
}

.flex {
  display: flex;
}

.align-item-center {
  align-items: center;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
//::-webkit-scrollbar-track {
//  background: inherit;
//}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #DDDDDD;
}

.margin-top-20 {
  margin-top: 20px;
}
.margin-top-32{
  margin-top: 32px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-right-24 {
  margin-right: 24px;
}

.channel-icon {
  font-size: 24px;
}

.checkbox-icon {
  font-size: 18px;
}

.flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.font-18 {
  font-size: 18px;
}

.tab-container-wrap {
  padding: 20px 15px;
  border-bottom: 1px solid #C5C5C7;
  //margin-bottom: 20px;
}
.margin-bottom-10{
  margin-bottom: 10px;
}
.margin-bottom-16 {
  margin-bottom: 16px;
}
.margin-left-10{
  margin-left:10px;
}
.margin-right-10{
  margin-right:10px;
}

@media screen and (min-width: 1441px) {
  .page-with-fixed-header-and-tab {
    .tab-container {
      .channel-selection-box {
        left: -7px;
      }
    }
  }
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-13 {
  font-size: 13px;
}

.font-24 {
  font-size: 24px;
}

.btn-wrapper {
  margin-top: 32px;
  margin-bottom: 30px;
}

.close-square {
  border: 1px solid #C5C5C7;
  border-radius: 5px;
  padding: 1px;
}

.tooltip-info-icon {
  font-size: 22px;
  color: #C5C5C7;
}

//@media screen and (max-width: 426px) {
//  .section-container {
//    height:100vh;
//}
//}

.mbsc-popup-overlay {
  margin: 0 !important;
}

.img-placeholder-icon {
  font-size: 56px;
  color: #C5C5C7;
}

.divider {
  border-top: 1px solid #C5C5C7;
}

.time {
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #F5F5F5;
  text-align: center;
  border: none;
  width: 60px;
}

.margin-top-2 {
  margin-top: 2px;
}

.margin-top-15 {
  margin-top: 15px;
}
.margin-top-5{
  margin-top: 5px;
}


.mbsc-ios.mbsc-form-control-wrapper:before, .mbsc-ios.mbsc-form-control-wrapper:after {
  border-top: none !important;
}


.message {
  margin-top: 10px;
  text-align: left;
  line-height: 22px;
  letter-spacing: -0.4px;
  cursor: pointer;
}

.message a {
  color: #D10A11;
}

.message ul {
  list-style-type: disc !important;
  color: #D10A11 !important;
  list-style-position: inside;
  //padding-left: 10px !important;
}

.message ol {
  color: #D10A11 !important;
  list-style-position: inside;
  //padding-left: 10px !important;
}

.message p {
  text-align: left;
  margin: 0;
  //margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.4px;
}

.height-27 {
  height: 27px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}
.searchicon {
  //padding: 11px 15px;
  background-color: #000000;
  border-radius: 8px;
  //flex-basis: 13%;
  text-align: center;
  height: 42px;
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;

  ion-icon {
    color: #FFFFFF;
    font-size: 18px;
  }
}
.delete-btn {
  display: flex;
  justify-content: center;
  flex-basis: 50%;
  padding: 11px 20px;
  border-radius: 90px;
  background: #DA291C;
  color: #FFFFFF;
  font-size: 16px;
  letter-spacing: -0.02px;
  line-height: 19.36px;
  ion-icon{
    font-size: 18px;
    margin-right: 10px;
  }
}

.pickedup-btn {
  display: flex;
  justify-content: center;
  flex-basis: 50%;
  margin-left: 10px;
  padding: 11px 20px;
  border-radius: 90px;
  background: #00D082;
  color: #FFFFFF;
  font-size: 16px;
  letter-spacing: -0.02px;
  line-height: 19.36px;
  ion-icon{
    font-size: 18px;
    margin-right: 10px;
  }
}
.delete-price-icon{
  color:#D10A11;
  font-size: 22px;
}
.restrictions {
  border: 1px solid #C5C5C7;
  border-radius: 8px;
  padding: 15px;

  .title {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.4px;
    font-weight: 400;
    color: #1c1c1c;
    margin-bottom: 0;
  }
}
.cursor-pointer{
  cursor: pointer;
}
.alkohol-bg{
  background: #F5F5F5;
}
.width-100{
  width: 100%;
}
.justify-content-space-between{
  justify-content: space-between;
}
.position-relative{
  position: relative;
}
